exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".modal{padding:0 2rem 0}.modal__title{margin-bottom:2rem}.modal__footer{margin-top:2rem;position:relative}.modal__footer:before{display:block;content:\"\";position:absolute;top:0;left:50%;transform:translateX(-50%);background:#c4c4c4;height:1px;width:calc(100% + 2rem * 2)}.modal__cancelBtn{font-size:16px;color:#21125e;background:transparent;outline:none;border:none;box-shadow:none;transform:none;text-decoration:underline;padding:0;margin-right:1rem}.modal__cancelBtn:hover{background:transparent}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/Modal/scss/index.scss"],"names":[],"mappings":"AAA6C,OAAO,gBAAgB,CAAC,cAAc,kBAAkB,CAAC,eAAe,gBAAgB,iBAAiB,CAAC,sBAAsB,cAAc,WAAW,kBAAkB,MAAM,SAAS,2BAA2B,mBAAmB,WAAW,2BAA2B,CAAC,kBAAkB,eAAe,cAAc,uBAAuB,aAAa,YAAY,gBAAgB,eAAe,0BAA0B,UAAU,iBAAiB,CAAC,wBAAwB,sBAAsB,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.modal{padding:0 2rem 0}.modal__title{margin-bottom:2rem}.modal__footer{margin-top:2rem;position:relative}.modal__footer:before{display:block;content:\"\";position:absolute;top:0;left:50%;transform:translateX(-50%);background:#c4c4c4;height:1px;width:calc(100% + 2rem * 2)}.modal__cancelBtn{font-size:16px;color:#21125e;background:transparent;outline:none;border:none;box-shadow:none;transform:none;text-decoration:underline;padding:0;margin-right:1rem}.modal__cancelBtn:hover{background:transparent}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};