exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".product-filters{background-color:#f6f6f6}.product-filters__grid{display:grid;grid-template-columns:repeat(4, 1fr);grid-gap:2rem;padding:1rem 0}@media (max-width: 992px){.product-filters__grid{grid-template-columns:repeat(2, 1fr)}}@media (max-width: 540px){.product-filters__grid{grid-template-columns:1fr}}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/ProductFilters/scss/index.scss"],"names":[],"mappings":"AAA6C,iBAAiB,wBAAwB,CAAC,uBAAuB,aAAa,qCAAqC,cAAc,cAAc,CAAC,0BAA0B,uBAAuB,oCAAoC,CAAC,CAAC,0BAA0B,uBAAuB,yBAAyB,CAAC,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.product-filters{background-color:#f6f6f6}.product-filters__grid{display:grid;grid-template-columns:repeat(4, 1fr);grid-gap:2rem;padding:1rem 0}@media (max-width: 992px){.product-filters__grid{grid-template-columns:repeat(2, 1fr)}}@media (max-width: 540px){.product-filters__grid{grid-template-columns:1fr}}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};