exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".login-form .input{margin-bottom:1rem}.login-form__button{margin-top:2rem;padding:0 1.5rem;text-align:center}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/LoginForm/scss/index.scss"],"names":[],"mappings":"AAA6C,mBAAmB,kBAAkB,CAAC,oBAAoB,gBAAgB,iBAAiB,iBAAiB,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.login-form .input{margin-bottom:1rem}.login-form__button{margin-top:2rem;padding:0 1.5rem;text-align:center}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};