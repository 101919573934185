exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".order-details h3{font-weight:600;margin:2.5rem 0 .5rem}.order-details__link{text-decoration:underline;font-size:.875rem;color:#7d7d7d;margin-top:2rem;display:inline-block}@media (min-width: 540px){.order-details__link{margin-top:2.5rem}}.order-details__status{color:#7d7d7d}.order-details__summary{margin-top:1.5rem}.order-details__summary h4{border-bottom:1px solid #f6f6f6;font-weight:600;margin-bottom:1rem;padding-bottom:1rem;text-transform:uppercase}@media (min-width: 540px){.order-details__summary{display:grid;grid-template-columns:1fr 1fr 1fr}}\n", "", {"version":3,"sources":["/opt/build/repo/src/userAccount/views/OrderDetails/scss/index.scss"],"names":[],"mappings":"AAA6C,kBAAkB,gBAAgB,qBAAqB,CAAC,qBAAqB,0BAA0B,kBAAkB,cAAc,gBAAgB,oBAAoB,CAAC,0BAA0B,qBAAqB,iBAAiB,CAAC,CAAC,uBAAuB,aAAa,CAAC,wBAAwB,iBAAiB,CAAC,2BAA2B,gCAAgC,gBAAgB,mBAAmB,oBAAoB,wBAAwB,CAAC,0BAA0B,wBAAwB,aAAa,iCAAiC,CAAC,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.order-details h3{font-weight:600;margin:2.5rem 0 .5rem}.order-details__link{text-decoration:underline;font-size:.875rem;color:#7d7d7d;margin-top:2rem;display:inline-block}@media (min-width: 540px){.order-details__link{margin-top:2.5rem}}.order-details__status{color:#7d7d7d}.order-details__summary{margin-top:1.5rem}.order-details__summary h4{border-bottom:1px solid #f6f6f6;font-weight:600;margin-bottom:1rem;padding-bottom:1rem;text-transform:uppercase}@media (min-width: 540px){.order-details__summary{display:grid;grid-template-columns:1fr 1fr 1fr}}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};