import * as React from "react";
import { generatePath } from "react-router";
import { Button } from "../../../components";
import { CartLineInterface } from "../../../components/CartProvider/context";
import { CartSummary, CheckboxField, Steps } from "../../components";
import { CheckoutStep } from "../../context";
import { shippingAddressUrl } from "../../routes";
import "./scss/index.scss";
import { ICheckoutData, IShippingPageProps } from "./types";

const computeCheckoutData = (lines: CartLineInterface[]): ICheckoutData => ({
  ...(lines && {
    lines: lines.map(({ quantity, variantId }) => ({
      quantity,
      variantId,
    })),
  }),
});

const Page: React.FC<IShippingPageProps> = ({
  acknowledgments,
  checkoutId,
  checkout,
  createCheckout: [
    create,
    { loading: createCheckoutLoading, error: createCheckoutError },
  ],
  proceedToNextStepData,
  shop,
  user,
  lines,
  update,
  updateMeta: [
    updateMetaMutation,
    { loading: updateMetaLoading, error: updateMetaError },
  ],
}) => {
  const onSaveAcknowledgmentsHandler = async () => {
    const newId = { id: checkoutId };
    if (!checkoutId) {
      const data = computeCheckoutData(lines);
      const creationResult = await create({
        checkoutInput: {
          lines: data.lines,
        },
      });
      newId.id = creationResult.data && creationResult.data.checkout.id;
    }

    const result = {
      clinical: null,
      dataUse: null,
      estimatedTimeline: null,
      requiredAmounts: null,
      specimenDepletion: null,
      specimenExtraction: null,
      specimenRetrospective: null,
    };

    const results = await Object.keys(acknowledgments).map(async field => {
      const r = await updateMetaMutation({
        checkoutId: newId.id,
        input: {
          clientName: "srs",
          key: field,
          namespace: "acknowledgements",
          value: acknowledgments[field] + "",
        },
      });
      const metaResult =
        r.data &&
        r.data.checkout.meta
          .find(e => e.namespace === "acknowledgements")
          .clients[0].metadata.find(o => o.key === field);
      result[field] = metaResult.value;
      return metaResult;
    });
    const resolvedPromise = Promise.all(results);
    await resolvedPromise;
    return result;
  };

  const onProceedToShipping = async () => {
    const { history, token } = proceedToNextStepData;
    const result = await onSaveAcknowledgmentsHandler();
    const canProceed = Object.values(result).every(e => e === "true");
    if (canProceed) {
      history.push(generatePath(shippingAddressUrl, { token }));
    }
  };

  return (
    <CartSummary checkout={checkout}>
      <Steps
        step={CheckoutStep.Acknowledgments}
        token={proceedToNextStepData.token}
        checkout={checkout}
      >
        <p className="checkout-step-description">
          Before beginning the specimen and data request application, please
          acknowledge that you have read the following by initialing each
          section.
        </p>

        <div className="checkout-step-section">
          <h5>CBTTC Data Use Agreement</h5>
          <a
            className="checkout-step-attachment"
            href="https://redcap.chop.edu/surveys/index.php?pid=21977&__passthru=DataEntry%2Ffile_download.php&type=attachment&doc_id_hash=55d96450c8db14a24805b58144cd9d914632b9ad&instance=1&id=620122&s=A7M873HMN8"
          >
            Attachment:<span>CBTTC Data Use Agreement.pdf</span>
            (0.11 MB)
          </a>
          <CheckboxField
            checkboxText="Please read and agree to the attached Data Use Agreement. If you are requesting data use, please sign the agreement and provide additional signatures as necessary. If you are a member of a CBTTC institution, you have institutional approval for access and management. You do not need to sign another agreement."
            checkboxLabel="I acknowledge that I have read and agree to adhere to the policies outlined in the Data Use Agreement."
            onCheck={value => {
              update({
                acknowledgments: {
                  ...acknowledgments,
                  dataUse: value + "",
                },
              });
            }}
            checked={acknowledgments.dataUse === "true"}
          />
        </div>

        <div className="checkout-step-section">
          <h5>DISCLAIMER REGARDING SPECIMEN EXTRACTIONS</h5>
          <CheckboxField
            checkboxText="Please note that while the utmost care is taken during the collection, processing and storage of the specimens, we cannot guarantee that DNA or RNA can be extracted that passes concentration and quality requirements. Also, the specimen weights may vary due to the difficulty on getting an accurate measurement of the weight. This is due to the specimens being frozen when weighed. The specimens are left on the scale for as little time as possible to prevent tissue degradation and the change in temperature from storage to being weighed can affect the accuracy of the measurement."
            checkboxLabel="I acknowledge that I have read the disclaimer regarding specimen extractions."
            onCheck={value => {
              update({
                acknowledgments: {
                  ...acknowledgments,
                  specimenExtraction: value + "",
                },
              });
            }}
            checked={acknowledgments.specimenExtraction === "true"}
          />
        </div>

        <div className="checkout-step-section">
          <h5>DISCLAIMER REGARDING REQUIRED AMOUNTS FOR DIFFERENT PROCESSES</h5>
          <CheckboxField
            checkboxText="Please note that if the specimen(s) you are requesting do not fulfill the required amounts for processing, we will be unable to satisfy the request."
            checkboxLabel="I acknowledge that I have read the disclaimer regarding the required amounts for different processes."
            onCheck={value => {
              update({
                acknowledgments: {
                  ...acknowledgments,
                  requiredAmounts: value + "",
                },
              });
            }}
            checked={acknowledgments.requiredAmounts === "true"}
          />
        </div>

        <div className="checkout-step-section">
          <h5>DISCLAIMER REGARDING RETROSPECTIVE SPECIMENS</h5>
          <CheckboxField
            checkboxText="For any specimens collected on a protocol other than the CBTTC Specimen Collection Protocol, we cannot guarantee that DNA and RNA will be able to be extracted, as we cannot confirm the quality of the collection, processing or storage procedures."
            checkboxLabel="I acknowledge that I have read the disclaimer regarding retrospective specimens."
            onCheck={value => {
              update({
                acknowledgments: {
                  ...acknowledgments,
                  specimenRetrospective: value + "",
                },
              });
            }}
            checked={acknowledgments.specimenRetrospective === "true"}
          />
        </div>

        <div className="checkout-step-section">
          <h5>DISCLAIMER REGARDING SPECIMEN DEPLETION</h5>
          <CheckboxField
            checkboxText="If your request results in the depletion of a specimen or subject, or if there is only enough for one extraction from the specimen/subject that you are requesting, this may result in questions from the Scientific Committee."
            checkboxLabel="I acknowledge that I have read the disclaimer regarding specimen depletion."
            onCheck={value => {
              update({
                acknowledgments: {
                  ...acknowledgments,
                  specimenDepletion: value + "",
                },
              });
            }}
            checked={acknowledgments.specimenDepletion === "true"}
          />
        </div>

        <div className="checkout-step-section">
          <h5>DISCLAIMER REGARDING CLINICAL DATA</h5>
          <CheckboxField
            checkboxText="Please note that if you are using any clinical data in your project that this data could change after the submission of your query. Please indicate this when you report the results of your project."
            checkboxLabel="I acknowledge that I have read the disclaimer regarding clinical data."
            onCheck={value => {
              update({
                acknowledgments: {
                  ...acknowledgments,
                  clinical: value + "",
                },
              });
            }}
            checked={acknowledgments.clinical === "true"}
          />
        </div>

        <div className="checkout-step-section">
          <h5>Specimen request general timeline</h5>
          <a
            className="checkout-step-attachment"
            href="https://redcap.chop.edu/surveys/index.php?pid=21977&__passthru=DataEntry%2Ffile_download.php&type=attachment&doc_id_hash=5468195478bd832d61ed408df9350dbef71f8ad7&instance=1&id=650520&s=A7M873HMN8"
          >
            Attachment:<span>Specimen request general timeline.pdf</span>
            (0.1 MB)
          </a>
          <CheckboxField
            checkboxText="Please read the attached estimated timeline for the specimen request process."
            checkboxLabel="I acknowledge that I have read the estimated timeline for the specimen request process."
            onCheck={value => {
              update({
                acknowledgments: {
                  ...acknowledgments,
                  estimatedTimeline: value + "",
                },
              });
            }}
            checked={acknowledgments.estimatedTimeline === "true"}
          />
        </div>

        <Button
          type="submit"
          disabled={Object.values(acknowledgments).includes("false")}
          onClick={() => {
            onProceedToShipping();
          }}
        >
          CONTINUE TO SHIPPING
        </Button>
      </Steps>
    </CartSummary>
  );
};

export default Page;
