exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".social-icon{padding:1rem .5rem}.social-icon svg *{transition:0.3s}.social-icon:hover svg *{fill:#51e9d2}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/SocialMediaIcon/scss/index.scss"],"names":[],"mappings":"AAA6C,aAAa,kBAAkB,CAAC,mBAAmB,eAAe,CAAC,yBAAyB,YAAY,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.social-icon{padding:1rem .5rem}.social-icon svg *{transition:0.3s}.social-icon:hover svg *{fill:#51e9d2}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};