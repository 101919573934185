var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".breadcrumbs{display:inline-flex;flex-wrap:wrap;margin:1rem 0}.breadcrumbs li:not(:last-of-type):after{content:url(" + escape(require("../../../images/breadcrumbs-arrow.svg")) + ");display:inline-block;margin:0 .5rem}.breadcrumbs li a{color:#7d7d7d;text-decoration:none;vertical-align:middle;transition:0.3s}.breadcrumbs li a:hover,.breadcrumbs li a:focus{color:#21125e}.breadcrumbs__active a{color:#323232;font-weight:600}.breadcrumbs__active a:hover{color:#323232}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/Breadcrumbs/scss/index.scss"],"names":[],"mappings":"AAA6C,aAAa,oBAAoB,eAAe,aAAa,CAAC,yCAAyC,sCAAqD,qBAAqB,cAAc,CAAC,kBAAkB,cAAc,qBAAqB,sBAAsB,eAAe,CAAC,gDAAgD,aAAa,CAAC,uBAAuB,cAAc,eAAe,CAAC,6BAA6B,aAAa,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.breadcrumbs{display:inline-flex;flex-wrap:wrap;margin:1rem 0}.breadcrumbs li:not(:last-of-type):after{content:url(\"../../../images/breadcrumbs-arrow.svg\");display:inline-block;margin:0 .5rem}.breadcrumbs li a{color:#7d7d7d;text-decoration:none;vertical-align:middle;transition:0.3s}.breadcrumbs li a:hover,.breadcrumbs li a:focus{color:#21125e}.breadcrumbs__active a{color:#323232;font-weight:600}.breadcrumbs__active a:hover{color:#323232}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};