exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".order-confirmation h3{color:#7d7d7d;font-weight:900;line-height:normal;margin:4rem 0 2rem;text-align:center;text-transform:uppercase}@media (min-width: 992px){.order-confirmation h3{font-size:3rem}}.order-confirmation__info{color:#7d7d7d;margin:2.5rem 0 7.5rem;text-align:center}.order-confirmation__actions{margin:0 auto}.order-confirmation__actions button{display:block;margin:0 auto .5rem;width:88%}@media (min-width: 992px){.order-confirmation__actions{text-align:center}.order-confirmation__actions button{display:inline-block;margin:0 1rem;padding-left:0;padding-right:0;width:300px}}\n", "", {"version":3,"sources":["/opt/build/repo/src/views/OrderConfirmation/scss/index.scss"],"names":[],"mappings":"AAA6C,uBAAuB,cAAc,gBAAgB,mBAAmB,mBAAmB,kBAAkB,wBAAwB,CAAC,0BAA0B,uBAAuB,cAAc,CAAC,CAAC,0BAA0B,cAAc,uBAAuB,iBAAiB,CAAC,6BAA6B,aAAa,CAAC,oCAAoC,cAAc,oBAAoB,SAAS,CAAC,0BAA0B,6BAA6B,iBAAiB,CAAC,oCAAoC,qBAAqB,cAAc,eAAe,gBAAgB,WAAW,CAAC,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.order-confirmation h3{color:#7d7d7d;font-weight:900;line-height:normal;margin:4rem 0 2rem;text-align:center;text-transform:uppercase}@media (min-width: 992px){.order-confirmation h3{font-size:3rem}}.order-confirmation__info{color:#7d7d7d;margin:2.5rem 0 7.5rem;text-align:center}.order-confirmation__actions{margin:0 auto}.order-confirmation__actions button{display:block;margin:0 auto .5rem;width:88%}@media (min-width: 992px){.order-confirmation__actions{text-align:center}.order-confirmation__actions button{display:inline-block;margin:0 1rem;padding-left:0;padding-right:0;width:300px}}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};