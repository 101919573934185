exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".checkout-step-description{font-size:.875rem;color:#7d7d7d;margin-bottom:1.5rem}.checkout-step-attachment{font-size:.875rem;padding-left:3rem}.checkout-step-attachment span{color:#21125e;padding-left:.5rem;padding-right:.5rem;text-decoration:underline}.checkout-step-attachment-left{font-size:.875rem;color:#7d7d7d}.checkout-step-attachment-left span{color:#21125e;padding-left:.5rem;padding-right:.5rem;text-decoration:underline}.checkout-step-section{margin-bottom:2rem}.checkout-step-section h5{color:#7d7d7d;font-weight:600;border-bottom:0.5px solid #c4c4c4;padding-bottom:.5rem;margin-bottom:1rem;text-transform:uppercase}\n", "", {"version":3,"sources":["/opt/build/repo/src/checkout/views/Acknowledgments/scss/index.scss"],"names":[],"mappings":"AAA6C,2BAA2B,kBAAkB,cAAc,oBAAoB,CAAC,0BAA0B,kBAAkB,iBAAiB,CAAC,+BAA+B,cAAc,mBAAmB,oBAAoB,yBAAyB,CAAC,+BAA+B,kBAAkB,aAAa,CAAC,oCAAoC,cAAc,mBAAmB,oBAAoB,yBAAyB,CAAC,uBAAuB,kBAAkB,CAAC,0BAA0B,cAAc,gBAAgB,kCAAkC,qBAAqB,mBAAmB,wBAAwB,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.checkout-step-description{font-size:.875rem;color:#7d7d7d;margin-bottom:1.5rem}.checkout-step-attachment{font-size:.875rem;padding-left:3rem}.checkout-step-attachment span{color:#21125e;padding-left:.5rem;padding-right:.5rem;text-decoration:underline}.checkout-step-attachment-left{font-size:.875rem;color:#7d7d7d}.checkout-step-attachment-left span{color:#21125e;padding-left:.5rem;padding-right:.5rem;text-decoration:underline}.checkout-step-section{margin-bottom:2rem}.checkout-step-section h5{color:#7d7d7d;font-weight:600;border-bottom:0.5px solid #c4c4c4;padding-bottom:.5rem;margin-bottom:1rem;text-transform:uppercase}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};