exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".cart-summary{background-color:#f6f6f6;padding:1rem}.cart-summary p,.cart-summary h4{text-transform:uppercase;font-weight:600}.cart-summary__header{padding-bottom:1rem}.cart-summary__product-item{border-top:1px solid #c4c4c4;padding:1rem 0;display:flex;align-items:flex-start}.cart-summary__product-item img{width:4.6rem;height:4.6rem;margin-right:1rem}.cart-summary__product-item div{width:100%}.cart-summary__product-item__details{color:#c4c4c4;display:flex;justify-content:space-between}.cart-summary__product-item__details span{font-size:.875rem}.cart-summary__totals{display:flex;justify-content:space-between;padding:1rem 0;border-top:1px solid #c4c4c4}\n", "", {"version":3,"sources":["/opt/build/repo/src/checkout/components/CartSummary/scss/index.scss"],"names":[],"mappings":"AAA6C,cAAc,yBAAyB,YAAY,CAAC,iCAAiC,yBAAyB,eAAe,CAAC,sBAAsB,mBAAmB,CAAC,4BAA4B,6BAA6B,eAAe,aAAa,sBAAsB,CAAC,gCAAgC,aAAa,cAAc,iBAAiB,CAAC,gCAAgC,UAAU,CAAC,qCAAqC,cAAc,aAAa,6BAA6B,CAAC,0CAA0C,iBAAiB,CAAC,sBAAsB,aAAa,8BAA8B,eAAe,4BAA4B,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.cart-summary{background-color:#f6f6f6;padding:1rem}.cart-summary p,.cart-summary h4{text-transform:uppercase;font-weight:600}.cart-summary__header{padding-bottom:1rem}.cart-summary__product-item{border-top:1px solid #c4c4c4;padding:1rem 0;display:flex;align-items:flex-start}.cart-summary__product-item img{width:4.6rem;height:4.6rem;margin-right:1rem}.cart-summary__product-item div{width:100%}.cart-summary__product-item__details{color:#c4c4c4;display:flex;justify-content:space-between}.cart-summary__product-item__details span{font-size:.875rem}.cart-summary__totals{display:flex;justify-content:space-between;padding:1rem 0;border-top:1px solid #c4c4c4}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};