exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".address-book-container{width:100%}\n", "", {"version":3,"sources":["/opt/build/repo/src/account/AddressBook/scss/index.scss"],"names":[],"mappings":"AAA6C,wBAAwB,UAAU,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.address-book-container{width:100%}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};