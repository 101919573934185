exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".carousel__control{padding:0.2rem 0.5rem;background-color:#fff;box-shadow:0px 0px 10px 0px rgba(0,0,0,0.25)}.carousel__control:hover{cursor:pointer}.carousel__control--right{margin-right:-1.3rem}@media (max-width: 1140px){.carousel__control--right{margin-right:0}}.carousel__control--right svg{transform:rotate(180deg);margin-top:0.5rem}.carousel__control--left{margin-left:-1.3rem}@media (max-width: 1140px){.carousel__control--left{margin-left:0}}.carousel__control--left svg{margin-top:0.4rem}.carousel .slider-slide{text-align:center}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/Carousel/scss/index.scss"],"names":[],"mappings":"AAA6C,mBAAmB,sBAAsB,sBAAsB,4CAA4C,CAAC,yBAAyB,cAAc,CAAC,0BAA0B,oBAAoB,CAAC,2BAA2B,0BAA0B,cAAc,CAAC,CAAC,8BAA8B,yBAAyB,iBAAiB,CAAC,yBAAyB,mBAAmB,CAAC,2BAA2B,yBAAyB,aAAa,CAAC,CAAC,6BAA6B,iBAAiB,CAAC,wBAAwB,iBAAiB,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.carousel__control{padding:0.2rem 0.5rem;background-color:#fff;box-shadow:0px 0px 10px 0px rgba(0,0,0,0.25)}.carousel__control:hover{cursor:pointer}.carousel__control--right{margin-right:-1.3rem}@media (max-width: 1140px){.carousel__control--right{margin-right:0}}.carousel__control--right svg{transform:rotate(180deg);margin-top:0.5rem}.carousel__control--left{margin-left:-1.3rem}@media (max-width: 1140px){.carousel__control--left{margin-left:0}}.carousel__control--left svg{margin-top:0.4rem}.carousel .slider-slide{text-align:center}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};