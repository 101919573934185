exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".product-list-item{text-align:center}.product-list-item td{padding:.25rem}.product-list-item__title{text-transform:uppercase;font-weight:600;margin-top:1rem;margin-left:1rem;color:#21125e;text-align:left}.product-list-item__category{color:#c4c4c4;text-align:left}.product-list-item__price{font-weight:600;margin:1rem 0 0;text-align:left}.product-list-item__attribute{font-weight:600;margin:1rem 0 0;text-align:right}.product-list-item__image{background-color:#f1f5f5;display:flex;height:350px;justify-content:center}.product-list-item__image img{object-fit:contain;max-width:100%}@media (max-width: 540px){.product-list-item__image{height:210px}}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/ProductListItem/scss/index.scss"],"names":[],"mappings":"AAA6C,mBAAmB,iBAAiB,CAAC,sBAAsB,cAAc,CAAC,0BAA0B,yBAAyB,gBAAgB,gBAAgB,iBAAiB,cAAc,eAAe,CAAC,6BAA6B,cAAc,eAAe,CAAC,0BAA0B,gBAAgB,gBAAgB,eAAe,CAAC,8BAA8B,gBAAgB,gBAAgB,gBAAgB,CAAC,0BAA0B,yBAAyB,aAAa,aAAa,sBAAsB,CAAC,8BAA8B,mBAAmB,cAAc,CAAC,0BAA0B,0BAA0B,YAAY,CAAC,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.product-list-item{text-align:center}.product-list-item td{padding:.25rem}.product-list-item__title{text-transform:uppercase;font-weight:600;margin-top:1rem;margin-left:1rem;color:#21125e;text-align:left}.product-list-item__category{color:#c4c4c4;text-align:left}.product-list-item__price{font-weight:600;margin:1rem 0 0;text-align:left}.product-list-item__attribute{font-weight:600;margin:1rem 0 0;text-align:right}.product-list-item__image{background-color:#f1f5f5;display:flex;height:350px;justify-content:center}.product-list-item__image img{object-fit:contain;max-width:100%}@media (max-width: 540px){.product-list-item__image{height:210px}}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};