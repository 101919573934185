exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".search-page__header{background-color:#190c4e;padding:4rem 0}.search-page__header__input .input__label{text-transform:uppercase;font-size:1.5rem;font-weight:900}.search-page__header__input .input__field{border:none;box-shadow:none;border-bottom:1px solid #fff;font-size:4rem;padding:.5rem 0;color:#fff;text-transform:uppercase;font-weight:900}.search-page__header__input .input__field:focus{box-shadow:none;color:#fff}\n", "", {"version":3,"sources":["/opt/build/repo/src/views/Search/scss/index.scss"],"names":[],"mappings":"AAA6C,qBAAqB,yBAAyB,cAAc,CAAC,0CAA0C,yBAAyB,iBAAiB,eAAe,CAAC,0CAA0C,YAAY,gBAAgB,6BAA6B,eAAe,gBAAgB,WAAW,yBAAyB,eAAe,CAAC,gDAAgD,gBAAgB,UAAU,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.search-page__header{background-color:#190c4e;padding:4rem 0}.search-page__header__input .input__label{text-transform:uppercase;font-size:1.5rem;font-weight:900}.search-page__header__input .input__field{border:none;box-shadow:none;border-bottom:1px solid #fff;font-size:4rem;padding:.5rem 0;color:#fff;text-transform:uppercase;font-weight:900}.search-page__header__input .input__field:focus{box-shadow:none;color:#fff}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};