import * as React from "react";

import { LineI } from "../../../components/CartTable/ProductRow";

const Line: React.FC<Omit<LineI, "totalPrice">> = ({
  id,
  product,
  pricing,
  name,
  quantity,
}) => (
  <div key={id} className="cart-summary__product-item">
    {product.name}
  </div>
);
export default Line;
