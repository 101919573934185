exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".checkout-shipping-options__form{display:grid;grid-column-gap:1.25rem;grid-row-gap:1.25rem;grid-template-columns:1fr}@media (min-width: 992px){.checkout-shipping-options__form{grid-template-columns:1fr 1fr}}\n", "", {"version":3,"sources":["/opt/build/repo/src/checkout/views/ShippingOptions/scss/index.scss"],"names":[],"mappings":"AAA6C,iCAAiC,aAAa,wBAAwB,qBAAqB,yBAAyB,CAAC,0BAA0B,iCAAiC,6BAA6B,CAAC,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.checkout-shipping-options__form{display:grid;grid-column-gap:1.25rem;grid-row-gap:1.25rem;grid-template-columns:1fr}@media (min-width: 992px){.checkout-shipping-options__form{grid-template-columns:1fr 1fr}}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};