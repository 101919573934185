exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".price-filter{position:relative}.price-filter__dropdown{display:none;position:absolute;background-color:#fff;border:1px solid #21125e;box-shadow:0 0 0 1px #21125e;padding:1rem;width:20rem;justify-content:space-between;z-index:2}.price-filter__dropdown--visible{display:flex}.price-filter__dropdown .input{width:8rem}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/PriceRangeFilter/scss/index.scss"],"names":[],"mappings":"AAA6C,cAAc,iBAAiB,CAAC,wBAAwB,aAAa,kBAAkB,sBAAsB,yBAAyB,6BAA6B,aAAa,YAAY,8BAA8B,SAAS,CAAC,iCAAiC,YAAY,CAAC,+BAA+B,UAAU,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.price-filter{position:relative}.price-filter__dropdown{display:none;position:absolute;background-color:#fff;border:1px solid #21125e;box-shadow:0 0 0 1px #21125e;padding:1rem;width:20rem;justify-content:space-between;z-index:2}.price-filter__dropdown--visible{display:flex}.price-filter__dropdown .input{width:8rem}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};