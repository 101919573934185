import { createContext } from "react";

import { Checkout } from "./types/Checkout";

export enum CheckoutStep {
  Acknowledgments = 1,
  ShippingAddress,
  ProjectInformation,
  ShippingOption,
  BillingAddress,
  Payment,
  Review,
}

export interface CardData {
  lastDigits: string;
  ccType: string;
  token: string;
}

export interface Acknowledgments {
  clinical: string;
  dataUse: string;
  estimatedTimeline: string;
  requiredAmounts: string;
  specimenDepletion: string;
  specimenExtraction: string;
  specimenRetrospective: string;
}

export interface ProjectInformation {
  budget: string;
  description: string;
  projectFunding: string;
  projectProposal: string;
  projectTitle: string;
  requestDate: string;
}

export interface CheckoutContextInterface {
  acknowledgments?: Acknowledgments;
  syncWithCart?: boolean;
  syncUserCheckout?: boolean;
  dummyStatus?: string;
  cardData?: CardData;
  checkout?: Checkout;
  loading?: boolean;
  projectInformation?: ProjectInformation;
  shippingAsBilling?: boolean;
  step?: CheckoutStep;
  update?(checkoutData: CheckoutContextInterface): void;
  clear?(): void;
}

export const defaultContext = {
  acknowledgments: {
    clinical: "false",
    dataUse: "false",
    estimatedTimeline: "false",
    requiredAmounts: "false",
    specimenDepletion: "false",
    specimenExtraction: "false",
    specimenRetrospective: "false",
  },
  cardData: null,
  checkout: null,
  clear: () => null,
  dummyStatus: null,
  loading: false,
  projectInformation: {
    budget: null,
    description: null,
    projectFunding: null,
    projectProposal: null,
    projectTitle: null,
    requestDate: null,
  },
  shippingAsBilling: false,
  step: CheckoutStep.ShippingAddress,
  syncUserCheckout: false,
  syncWithCart: false,
  update: (checkoutData: {}) => null,
};

export const CheckoutContext = createContext<CheckoutContextInterface>(
  defaultContext
);

CheckoutContext.displayName = "CheckoutContext";
