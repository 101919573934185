exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".checkbox-container{margin-top:1rem;margin-bottom:1rem}.checkbox-container__field{margin-top:.5rem;padding-left:1rem}.checkbox-container-label{font-size:.875rem;color:#7d7d7d}.checkbox-container-checked{color:#000}\n", "", {"version":3,"sources":["/opt/build/repo/src/checkout/components/CheckboxField/scss/index.scss"],"names":[],"mappings":"AAA6C,oBAAoB,gBAAgB,kBAAkB,CAAC,2BAA2B,iBAAiB,iBAAiB,CAAC,0BAA0B,kBAAkB,aAAa,CAAC,4BAA4B,UAAU,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.checkbox-container{margin-top:1rem;margin-bottom:1rem}.checkbox-container__field{margin-top:.5rem;padding-left:1rem}.checkbox-container-label{font-size:.875rem;color:#7d7d7d}.checkbox-container-checked{color:#000}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};