exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".login{width:25rem;max-width:100vw}.login__tabs{display:flex}.login__tabs span{flex-grow:1;width:100%;background-color:#f6f6f6;text-transform:uppercase;font-weight:600;text-align:center;padding:1rem;border-bottom:1px solid #f6f6f6;cursor:pointer}.login__tabs span:first-of-type{border-right:1px solid #fff}.login__tabs span:last-of-type{border-left:1px solid #fff}.login__tabs span.active-tab{border-bottom:1px solid #51e9d2}.login__content{padding:1rem}.login__content .input{margin-bottom:1rem}.login__content__button{margin-top:2rem;padding:0 1.5rem;text-align:center}.login__content__password-reminder{color:#c4c4c4;font-size:.875rem;margin-top:2rem}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/OverlayManager/Login/scss/index.scss"],"names":[],"mappings":"AAA6C,OAAO,YAAY,eAAe,CAAC,aAAa,YAAY,CAAC,kBAAkB,YAAY,WAAW,yBAAyB,yBAAyB,gBAAgB,kBAAkB,aAAa,gCAAgC,cAAc,CAAC,gCAAgC,2BAA2B,CAAC,+BAA+B,0BAA0B,CAAC,6BAA6B,+BAA+B,CAAC,gBAAgB,YAAY,CAAC,uBAAuB,kBAAkB,CAAC,wBAAwB,gBAAgB,iBAAiB,iBAAiB,CAAC,mCAAmC,cAAc,kBAAkB,eAAe,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.login{width:25rem;max-width:100vw}.login__tabs{display:flex}.login__tabs span{flex-grow:1;width:100%;background-color:#f6f6f6;text-transform:uppercase;font-weight:600;text-align:center;padding:1rem;border-bottom:1px solid #f6f6f6;cursor:pointer}.login__tabs span:first-of-type{border-right:1px solid #fff}.login__tabs span:last-of-type{border-left:1px solid #fff}.login__tabs span.active-tab{border-bottom:1px solid #51e9d2}.login__content{padding:1rem}.login__content .input{margin-bottom:1rem}.login__content__button{margin-top:2rem;padding:0 1.5rem;text-align:center}.login__content__password-reminder{color:#c4c4c4;font-size:.875rem;margin-top:2rem}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};