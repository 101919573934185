exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".not-found-page{display:flex;align-items:center;justify-content:center;flex-direction:column;text-align:center;padding:2rem 1rem}.not-found-page__header{font-size:15rem;font-family:\"Montserrat\";font-weight:900;line-height:100%;color:#21125e;padding-bottom:20px}@media (max-width: 540px){.not-found-page__header{font-size:9rem}}.not-found-page__ruler{background:#21125e;min-width:10rem;height:2px;margin-top:1rem;margin-bottom:3rem}@media (max-width: 540px){.not-found-page__message p{display:inline}}.not-found-page__button{margin:2rem 0;min-width:88%}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/NotFound/scss/index.scss"],"names":[],"mappings":"AAA6C,gBAAgB,aAAa,mBAAmB,uBAAuB,sBAAsB,kBAAkB,iBAAiB,CAAC,wBAAwB,gBAAgB,yBAAyB,gBAAgB,iBAAiB,cAAc,mBAAmB,CAAC,0BAA0B,wBAAwB,cAAc,CAAC,CAAC,uBAAuB,mBAAmB,gBAAgB,WAAW,gBAAgB,kBAAkB,CAAC,0BAA0B,2BAA2B,cAAc,CAAC,CAAC,wBAAwB,cAAc,aAAa,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.not-found-page{display:flex;align-items:center;justify-content:center;flex-direction:column;text-align:center;padding:2rem 1rem}.not-found-page__header{font-size:15rem;font-family:\"Montserrat\";font-weight:900;line-height:100%;color:#21125e;padding-bottom:20px}@media (max-width: 540px){.not-found-page__header{font-size:9rem}}.not-found-page__ruler{background:#21125e;min-width:10rem;height:2px;margin-top:1rem;margin-bottom:3rem}@media (max-width: 540px){.not-found-page__message p{display:inline}}.not-found-page__button{margin:2rem 0;min-width:88%}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};