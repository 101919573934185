import "./scss/index.scss";

import * as React from "react";

import { Button, ProductListItem } from "..";
import Loader from "../Loader";

import { Product } from "../ProductListItem";

interface ProductsListProps {
  displayLoader: boolean;
  hasNextPage: boolean;
  notFound?: string | React.ReactNode;
  onLoadMore: () => void;
  products: Product[];
  totalCount: number;
  addToCart?(varinatId: string, quantity?: number): void;
}

export const ProductList: React.FC<ProductsListProps> = ({
  displayLoader,
  hasNextPage,
  notFound,
  onLoadMore,
  products,
  totalCount,
  addToCart,
}) => {
  const hasProducts = !!totalCount;

  return (
    <div className="products-list">
      <div className="products-list__products container">
        {hasProducts ? (
          <>
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>Name</th>
                  {hasProducts &&
                    products[0].attributes.map(
                      ({ attribute: { name, id } }) => (
                        <th
                          key={id}
                          className="products-list__products__header"
                        >
                          {name}
                        </th>
                      )
                    )}
                </tr>
              </thead>
              <tbody className="products-list__products__table">
                {products.map(product => (
                  <ProductListItem
                    key={product.id}
                    product={product}
                    addToCart={addToCart}
                  />
                ))}
              </tbody>
            </table>
            <div className="products-list__products__load-more">
              {displayLoader ? (
                <Loader />
              ) : (
                hasNextPage && (
                  <Button secondary onClick={onLoadMore}>
                    Load more products
                  </Button>
                )
              )}
            </div>
          </>
        ) : (
          <div className="products-list__products-not-found">{notFound}</div>
        )}
      </div>
    </div>
  );
};

ProductList.defaultProps = {
  notFound: "We couldn't find any product matching these conditions",
};

export default ProductList;
