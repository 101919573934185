exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".address-form button{margin:2rem 0 0 2rem}.address-form .react-select-wrapper{margin-bottom:1rem}.address-form__grid{display:grid;grid-gap:0 1rem}@media (min-width: 992px){.address-form__grid{grid-template-columns:1fr 1fr}}@media (min-width: 540px){.address-form__grid--modal{grid-template-columns:1fr 1fr}}@media (min-width: 540px){.address-form__grid--modal.address-form__grid--full{grid-template-columns:none}}@media (min-width: 992px){.address-form__grid--uneven{grid-template-columns:1fr 3fr;margin-right:9rem}}@media (min-width: 992px){.address-form__grid--full{grid-template-columns:none}}.address-form__copy-address{padding:1rem 0 2rem}.address-form .checkbox__bottom{display:block;margin-top:1.5rem}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/ShippingAddressForm/scss/index.scss"],"names":[],"mappings":"AAA6C,qBAAqB,oBAAoB,CAAC,oCAAoC,kBAAkB,CAAC,oBAAoB,aAAa,eAAe,CAAC,0BAA0B,oBAAoB,6BAA6B,CAAC,CAAC,0BAA0B,2BAA2B,6BAA6B,CAAC,CAAC,0BAA0B,oDAAoD,0BAA0B,CAAC,CAAC,0BAA0B,4BAA4B,8BAA8B,iBAAiB,CAAC,CAAC,0BAA0B,0BAA0B,0BAA0B,CAAC,CAAC,4BAA4B,mBAAmB,CAAC,gCAAgC,cAAc,iBAAiB,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.address-form button{margin:2rem 0 0 2rem}.address-form .react-select-wrapper{margin-bottom:1rem}.address-form__grid{display:grid;grid-gap:0 1rem}@media (min-width: 992px){.address-form__grid{grid-template-columns:1fr 1fr}}@media (min-width: 540px){.address-form__grid--modal{grid-template-columns:1fr 1fr}}@media (min-width: 540px){.address-form__grid--modal.address-form__grid--full{grid-template-columns:none}}@media (min-width: 992px){.address-form__grid--uneven{grid-template-columns:1fr 3fr;margin-right:9rem}}@media (min-width: 992px){.address-form__grid--full{grid-template-columns:none}}.address-form__copy-address{padding:1rem 0 2rem}.address-form .checkbox__bottom{display:block;margin-top:1.5rem}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};