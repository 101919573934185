exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".products-featured{padding:2rem 0 4rem}.products-featured h3{font-weight:600;margin-bottom:2rem;text-transform:uppercase}.products-featured a{color:#323232;display:inline-block;max-width:255px;text-decoration:none}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/ProductsFeatured/scss/index.scss"],"names":[],"mappings":"AAA6C,mBAAmB,mBAAmB,CAAC,sBAAsB,gBAAgB,mBAAmB,wBAAwB,CAAC,qBAAqB,cAAc,qBAAqB,gBAAgB,oBAAoB,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.products-featured{padding:2rem 0 4rem}.products-featured h3{font-weight:600;margin-bottom:2rem;text-transform:uppercase}.products-featured a{color:#323232;display:inline-block;max-width:255px;text-decoration:none}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};