exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".menu-dropdown{position:relative}.menu-dropdown__body{display:none;position:absolute;right:1rem;top:1.5rem;box-shadow:0px 6px 15px 3px rgba(0,0,0,0.25);background-color:#fff;padding:1rem;width:15rem}.menu-dropdown__body--visible{display:block;z-index:2}.menu-dropdown__body ul{display:flex;flex-direction:column;font-style:normal;font-weight:normal;line-height:2rem;align-items:flex-start}.menu-dropdown__body ul li{width:80%}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/MenuDropdown/scss/index.scss"],"names":[],"mappings":"AAA6C,eAAe,iBAAiB,CAAC,qBAAqB,aAAa,kBAAkB,WAAW,WAAW,6CAA6C,sBAAsB,aAAa,WAAW,CAAC,8BAA8B,cAAc,SAAS,CAAC,wBAAwB,aAAa,sBAAsB,kBAAkB,mBAAmB,iBAAiB,sBAAsB,CAAC,2BAA2B,SAAS,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.menu-dropdown{position:relative}.menu-dropdown__body{display:none;position:absolute;right:1rem;top:1.5rem;box-shadow:0px 6px 15px 3px rgba(0,0,0,0.25);background-color:#fff;padding:1rem;width:15rem}.menu-dropdown__body--visible{display:block;z-index:2}.menu-dropdown__body ul{display:flex;flex-direction:column;font-style:normal;font-weight:normal;line-height:2rem;align-items:flex-start}.menu-dropdown__body ul li{width:80%}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};