import { Trans } from "@lingui/react";
import React from "react";
import Media from "react-media";
import { ThemeContext } from "styled-components";

import * as S from "./styles";
import { IProps } from "./types";

const header = (matches: boolean) => (
  <S.HeaderRow>
    {matches && (
      <>
        <S.ProductsOrdered>
          <Trans id="# of Specimens" />
        </S.ProductsOrdered>
        <S.DateOfOrder>
          <Trans id="Date of Order" />
        </S.DateOfOrder>
      </>
    )}
    <S.Status>
      <Trans id="Status" />
    </S.Status>
  </S.HeaderRow>
);

export const OrderTabel: React.FC<IProps> = ({ orders, history }: IProps) => {
  const theme = React.useContext(ThemeContext);
  return (
    <S.Wrapper>
      <Media
        query={{
          minWidth: theme.breakpoints.mediumScreen,
        }}
      >
        {(matches: boolean) => {
          return (
            <>
              <S.Row>{header(matches)}</S.Row>
              {orders &&
                orders.map(order => {
                  const date = new Date(order.node.created);
                  return (
                    <S.Row
                      key={order.node.number}
                      onClick={evt => {
                        evt.stopPropagation();
                        history.push(`/order/${order.node.token}`);
                      }}
                    >
                      {matches ? (
                        <>
                          <S.ProductsOrdered>
                            {order.node.lines.length}
                          </S.ProductsOrdered>
                          <S.DateOfOrder>
                            {`${date.getMonth() +
                              1}/${date.getDate()}/${date.getFullYear()}`}
                          </S.DateOfOrder>
                        </>
                      ) : (
                        ""
                      )}
                      <S.Status>{order.node.statusDisplay}</S.Status>
                    </S.Row>
                  );
                })}
            </>
          );
        }}
      </Media>
    </S.Wrapper>
  );
};
