exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".account{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:stretch;margin-bottom:2rem}@media screen and (max-width: 540px){.account{flex-direction:column}}.account__menu{width:360px;margin-right:2rem}.account__content{width:100%}\n", "", {"version":3,"sources":["/opt/build/repo/src/views/Account/scss/index.scss"],"names":[],"mappings":"AAA6C,SAAS,aAAa,mBAAmB,iBAAiB,oBAAoB,kBAAkB,CAAC,qCAAqC,SAAS,qBAAqB,CAAC,CAAC,eAAe,YAAY,iBAAiB,CAAC,kBAAkB,UAAU,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.account{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:stretch;margin-bottom:2rem}@media screen and (max-width: 540px){.account{flex-direction:column}}.account__menu{width:360px;margin-right:2rem}.account__content{width:100%}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};