import { Input } from "@components/atoms";
import * as React from "react";
import { generatePath } from "react-router";
import { Button } from "../../../components";
import { CartSummary, Steps } from "../../components";
import { CheckoutStep } from "../../context";
import { shippingOptionsUrl } from "../../routes";
import "./scss/index.scss";
import { IProjectInformationProps } from "./types";

const Page: React.FC<IProjectInformationProps> = ({
  projectInformation,
  checkoutId,
  checkout,
  proceedToNextStepData,
  shop,
  user,
  lines,
  update,
  updateMeta: [
    updateMetaMutation,
    { loading: updateMetaLoading, error: updateMetaError },
  ],
}) => {
  const onSaveProjectInformationHandler = async () => {
    const result = {
      budget: null,
      description: null,
      projectFunding: null,
      projectProposal: null,
      projectTitle: null,
      requestDate: null,
    };

    const results = await Object.keys(projectInformation).map(async field => {
      const r = await updateMetaMutation({
        checkoutId,
        input: {
          clientName: "srs",
          key: field,
          namespace: "projectInformation",
          value: projectInformation[field] + "",
        },
      });
      const metaResult =
        r.data &&
        r.data.checkout.meta
          .find(e => e.namespace === "projectInformation")
          .clients[0].metadata.find(o => o.key === field);
      result[field] = metaResult.value;
      return metaResult;
    });
    const resolvedPromise = Promise.all(results);
    await resolvedPromise;
    return result;
  };

  const onProceedToShipping = async () => {
    const { history, token } = proceedToNextStepData;
    const result = await onSaveProjectInformationHandler();
    const canProceed = result.requestDate !== null;
    if (canProceed) {
      history.push(generatePath(shippingOptionsUrl, { token }));
    }
  };

  return (
    <CartSummary checkout={checkout}>
      <Steps
        step={CheckoutStep.ProjectInformation}
        token={proceedToNextStepData.token}
        checkout={checkout}
      >
        <Input
          label="Date of Request (Required)"
          value={
            projectInformation.requestDate ? projectInformation.requestDate : ""
          }
          onChange={e => {
            update({
              projectInformation: {
                ...projectInformation,
                requestDate: e.target.value,
              },
            });
          }}
        />
        <p className="checkout-step-description">
          The date that you are completing this form, in format "MM-DD-YYYY".
        </p>
        <Input
          label="Project Title"
          value={
            projectInformation.projectTitle
              ? projectInformation.projectTitle
              : ""
          }
          onChange={e => {
            update({
              projectInformation: {
                ...projectInformation,
                projectTitle: e.target.value,
              },
            });
          }}
        />
        <p className="checkout-step-description">(Optional)</p>
        <Input
          label="Description"
          value={
            projectInformation.description ? projectInformation.description : ""
          }
          onChange={e => {
            update({
              projectInformation: {
                ...projectInformation,
                description: e.target.value,
              },
            });
          }}
        />
        <p className="checkout-step-description">
          Indicate how the CBTTC clinical and/or specimen data will be used in
          your research.
        </p>
        <Input
          label="Project Funding"
          value={
            projectInformation.projectFunding
              ? projectInformation.projectFunding
              : ""
          }
          onChange={e => {
            update({
              projectInformation: {
                ...projectInformation,
                projectFunding: e.target.value,
              },
            });
          }}
        />
        <p className="checkout-step-description">
          How is the project being funded?
        </p>
        <Input
          label="Budget"
          value={projectInformation.budget ? projectInformation.budget : ""}
          onChange={e => {
            update({
              projectInformation: {
                ...projectInformation,
                budget: e.target.value,
              },
            });
          }}
        />
        <p className="checkout-step-description">
          If this project is approved by the Scientific Committee, there is
          potential funding available in the amount of $5,000. Please provide a
          budget and 2-3 sentence justification for review.
        </p>
        <Button
          type="submit"
          disabled={projectInformation.requestDate === null}
          onClick={() => {
            onProceedToShipping();
          }}
        >
          CONTINUE TO SHIPPING METHOD
        </Button>
      </Steps>
    </CartSummary>
  );
};

export default Page;
