import classNames from "classnames";
import React from "react";
import { CheckboxFieldProps } from "../../types";
import "./scss/index.scss";

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  checkboxText,
  checkboxLabel,
  checked,
  onCheck,
}) => {
  return (
    <div className="checkbox-container">
      <p>{checkboxText}</p>
      <div className="checkbox-container__field">
        <input
          type="checkbox"
          id={checkboxLabel}
          name={checkboxLabel}
          checked={checked}
          onChange={evt => {
            onCheck(!checked);
          }}
          readOnly
        />
        <label
          className={classNames("checkbox-container-label", {
            "checkbox-container-checked": checked,
          })}
          htmlFor={checkboxLabel}
        >
          {checkboxLabel}
        </label>
      </div>
    </div>
  );
};

export default CheckboxField;
