import "./scss/index.scss";

import * as React from "react";
import { Link } from "react-router-dom";

import { generateProductUrl } from "../../core/utils";

import { BasicProductFields } from "../../views/Product/types/BasicProductFields";
import { ProductAttributeFields } from "../../views/Product/types/ProductAttributeFields";

import { CartContext } from "../../components/CartProvider/context";
import AddToCart from "../ProductDescription/AddToCart";

export interface Product extends BasicProductFields {
  category?: {
    id: string;
    name: string;
  };
  attributes: ProductAttributeFields[];
}

interface ProductListItemProps {
  product: Product;
  addToCart?(varinatId: string, quantity?: number): void;
}

const ProductListItem: React.FC<ProductListItemProps> = ({
  product,
  addToCart,
}) => {
  const handleSubmit = () => {
    addToCart(product.variants[0].id, 1);
  };
  const { attributes } = product;
  return (
    <tr className="product-list-item">
      {addToCart && (
        <td>
          <CartContext.Consumer>
            {({ lines }) => (
              <AddToCart
                variant="small"
                onSubmit={handleSubmit}
                lines={lines}
                disabled={
                  lines.filter(
                    line => line.variantId === product.variants[0].id
                  ).length !== 0
                }
              />
            )}
          </CartContext.Consumer>
        </td>
      )}
      <td className="product-list-item__title">
        <Link to={generateProductUrl(product.id, product.name)}>
          {product.name}
        </Link>
      </td>
      {attributes &&
        attributes
          .filter(v => v.values !== null)
          .map(({ attribute: { name }, values }) => (
            <td key={name} className="product-list-item__attribute">
              {values.map(v => v.name).join(", ")}
            </td>
          ))}
    </tr>
  );
};

export default ProductListItem;
