exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".category__header{text-align:center;padding-top:4.5rem;padding-bottom:4.5rem;background-size:cover;background-color:rgba(33,18,94,0.15);background-blend-mode:multiply}.category__header__title{background-color:#fff;display:inline-block;transform:skew(-45deg);padding:1.5vw 9vw}.category__header__title h1{display:inline-block;transform:skew(45deg);font-weight:600;text-transform:uppercase;color:#21125e;font-size:8vw;font-weight:900}@media (min-width: 540px){.category__header__title h1{font-size:4vw}}\n", "", {"version":3,"sources":["/opt/build/repo/src/views/Category/scss/index.scss"],"names":[],"mappings":"AAA6C,kBAAkB,kBAAkB,mBAAmB,sBAAsB,sBAAsB,qCAAqC,8BAA8B,CAAC,yBAAyB,sBAAsB,qBAAqB,uBAAuB,iBAAiB,CAAC,4BAA4B,qBAAqB,sBAAsB,gBAAgB,yBAAyB,cAAc,cAAc,eAAe,CAAC,0BAA0B,4BAA4B,aAAa,CAAC,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.category__header{text-align:center;padding-top:4.5rem;padding-bottom:4.5rem;background-size:cover;background-color:rgba(33,18,94,0.15);background-blend-mode:multiply}.category__header__title{background-color:#fff;display:inline-block;transform:skew(-45deg);padding:1.5vw 9vw}.category__header__title h1{display:inline-block;transform:skew(45deg);font-weight:600;text-transform:uppercase;color:#21125e;font-size:8vw;font-weight:900}@media (min-width: 540px){.category__header__title h1{font-size:4vw}}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};