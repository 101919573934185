exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".c-option{background-color:#f6f6f6}\n", "", {"version":3,"sources":["/opt/build/repo/src/checkout/components/Option/scss/index.scss"],"names":[],"mappings":"AAA6C,UAAU,wBAAwB,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.c-option{background-color:#f6f6f6}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};