exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".checkout-payment__form{display:grid;grid-column-gap:1.25rem;grid-row-gap:1.25rem;grid-template-columns:1fr}@media (min-width: 992px){.checkout-payment__form>.c-option__content{padding:0 3rem 0 6rem}}.checkout-payment__form>.c-option__content .c-option{background-color:#fff}\n", "", {"version":3,"sources":["/opt/build/repo/src/checkout/views/Payment/scss/index.scss"],"names":[],"mappings":"AAA6C,wBAAwB,aAAa,wBAAwB,qBAAqB,yBAAyB,CAAC,0BAA0B,2CAA2C,qBAAqB,CAAC,CAAC,qDAAqD,qBAAqB,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.checkout-payment__form{display:grid;grid-column-gap:1.25rem;grid-row-gap:1.25rem;grid-template-columns:1fr}@media (min-width: 992px){.checkout-payment__form>.c-option__content{padding:0 3rem 0 6rem}}.checkout-payment__form>.c-option__content .c-option{background-color:#fff}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};