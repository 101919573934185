import * as React from "react";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";

import { generateProductUrl } from "../../../core/utils";
import removeImg from "../../../images/garbage.svg";
import { LineI } from "../../CartTable/ProductRow";

const ProductList: React.SFC<{
  lines: LineI[];
  remove(variantId: string): void;
}> = ({ lines, remove }) => (
  <ul className="cart__list">
    {lines.map(line => {
      const productUrl = generateProductUrl(line.product.id, line.product.name);
      return (
        <li key={line.id} className="cart__list__item">
          <div className="cart__list__item__details">
            <p>
              <ReactSVG
                path={removeImg}
                className="cart__list__item__details__delete-icon"
                onClick={() => remove(line.id)}
              />
              <Link to={productUrl}>{line.product.name}</Link>
            </p>
          </div>
        </li>
      );
    })}
  </ul>
);
export default ProductList;
