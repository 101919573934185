var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".dropdown-component .dropdown__control{border:none;background:transparent}.dropdown-component .dropdown__control--is-focused{box-shadow:none}.dropdown-component .dropdown__menu{border-radius:0}.dropdown-component .dropdown__indicator-separator{display:none}.dropdown-component .dropdown__dropdown-indicator{background:url(" + escape(require("../../../images/arrow.svg")) + ");background-repeat:no-repeat;background-position:center}.dropdown-component .dropdown__dropdown-indicator svg{display:none}.dropdown-component .dropdown__option--is-focused,.dropdown-component .dropdown__option--is-selected,.dropdown-component .dropdown__option:active{background-color:#f1f5f5;color:#323232}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/Dropdown/scss/index.scss"],"names":[],"mappings":"AAA6C,uCAAuC,YAAY,sBAAsB,CAAC,mDAAmD,eAAe,CAAC,oCAAoC,eAAe,CAAC,mDAAmD,YAAY,CAAC,kDAAkD,yCAA4C,4BAA4B,0BAA0B,CAAC,sDAAsD,YAAY,CAAC,kJAAkJ,yBAAyB,aAAa,CAAC","file":"index.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.dropdown-component .dropdown__control{border:none;background:transparent}.dropdown-component .dropdown__control--is-focused{box-shadow:none}.dropdown-component .dropdown__menu{border-radius:0}.dropdown-component .dropdown__indicator-separator{display:none}.dropdown-component .dropdown__dropdown-indicator{background:url(\"../../../images/arrow.svg\");background-repeat:no-repeat;background-position:center}.dropdown-component .dropdown__dropdown-indicator svg{display:none}.dropdown-component .dropdown__option--is-focused,.dropdown-component .dropdown__option--is-selected,.dropdown-component .dropdown__option:active{background-color:#f1f5f5;color:#323232}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};